<template>
  <div>
    <v-row>
      <v-col>
        <app-pagination @click="select($event)" :data="notifications">
          <v-list-item
            @click="handleViewNotification(item.id)"
            v-for="(item, index) in notifications.data"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title> {{ types[item.type] }} </v-list-item-title>
              <v-list-item-subtitle>
                <div class="d-flex align-center">
                  <v-icon color="#00A884" small class="mr-1">
                    mdi-whatsapp
                  </v-icon>
                  <small style="color: #00a884">
                    {{ statuses[notificationStatus(item)].text }}
                  </small>
                  <v-icon
                    class="ml-2"
                    v-if="statuses[notificationStatus(item)].statusIcon"
                    :color="statuses[notificationStatus(item)].statusIconColor"
                    small
                  >
                    {{ statuses[notificationStatus(item)].statusIcon }}
                  </v-icon>
                  {{ getResponse(item) }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              {{ $format.dateBr(item.created_at) }}
            </v-list-item-action>
          </v-list-item>
        </app-pagination>
      </v-col>
    </v-row>
    <ViewBillingNotificationDialog ref="ViewBillingNotificationDialog" />
  </div>
</template>

<script>
import ViewBillingNotificationDialog from "@/components/transactions/sections/ViewBillingNotificationDialog.vue";
export default {
  components: {
    ViewBillingNotificationDialog,
  },
  props: {
    customer: {},
  },

  data() {
    return {
      searchParams: {},

      notifications: {},
      headers: [
        { text: "Data", value: "created_at", align: "start" },
        { text: "Tipo", value: "type", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
      loading: false,

      types: {
        automatic_billing: "Cobrança automática",
        manual_billing: "Cobrança manual",
      },

      statuses: {
        error: {
          text: "Erro ao enviar mensagem",
          statusIcon: "mdi-alert-circle",
          statusIconColor: "error",
        },
        sending: {
          text: "Enviando Notificação",
          statusIcon: "mdi-clock-outline",
          statusIconColor: "gray",
        },
        pending: {
          text: "Enviando Notificação",
          statusIcon: "mdi-clock-outline",
          statusIconColor: "gray",
        },
        success: {
          text: "Notificação enviada",
          statusIcon: "mdi-check",
          statusIconColor: "gray",
        },
        RECEIVED: {
          text: "Notificação recebida",
          statusIcon: "mdi-check-all",
          statusIconColor: "gray",
        },
        READ: {
          text: "Notificação lida",
          statusIcon: "mdi-check-all",
          statusIconColor: "info",
        },
        SENT: {
          text: "Notificação enviada",
          statusIcon: "mdi-check-all",
          statusIconColor: "info",
        },
      },
    };
  },

  mounted() {
    this.select();
  },
  created() {
    this.searchParams.customer_id = this.$route.params.id;
  },

  methods: {
    select(page = 1) {
      this.searchParams.page = page;
      this.loading = true;
      this.$http
        .index("customer/customer-billing-notification", this.searchParams)
        .then((response) => {
          this.notifications = response.notifications;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    handleViewNotification(id) {
      this.$refs.ViewBillingNotificationDialog.open(id);
    },
    notificationStatus(notification) {
      if (notification.whatsapp_status) {
        return notification.whatsapp_status;
      }

      return notification.status;
    },
    getResponse(notification) {
      if (JSON.parse(notification.response).error) {
        return JSON.parse(notification.response).error ==
          "Phone number does not exist"
          ? "Número de telefone não existe"
          : "";
      }
    },
  },
};
</script>

<style>
</style>