<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
  >
    <v-card>
      <v-card-title>
        Notificação de {{ types[notification.type] }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <strong> Destinatário: </strong>
            {{ $format.phone(notification.number) }}
          </v-col>
          <v-col cols="12" md="6">
            <strong> Status: </strong>:
            <v-icon color="#00A884" small class="mr-1"> mdi-whatsapp </v-icon>
            <span style="color: #00a884">
              {{ statuses[notificationStatus(notification)].text }}
            </span>
            <v-icon
              class="ml-2"
              v-if="statuses[notificationStatus(notification)].statusIcon"
              :color="
                statuses[notificationStatus(notification)].statusIconColor
              "
              small
            >
              {{ statuses[notificationStatus(notification)].statusIcon }}
            </v-icon>
          </v-col>
          <v-col>
            <strong> Data </strong>:
            {{ $format.dateBr(notification.created_at) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col style="line-break: loose">
            <strong> Mensagem: </strong>{{ notification.message }}
          </v-col>
        </v-row>

        <!-- <v-row>
    <v-col>
      <span v-html="message" />
    </v-col>
  </v-row> -->
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" rounded outlined> Voltar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      notification: {
        status: "error",
        whatsapp_status: null,
      },

      types: {
        automatic_billing: "Cobrança automática",
        manual_billing: "Cobrança manual",
      },

      statuses: {
        error: {
          text: "Erro ao enviar mensagem",
          statusIcon: "mdi-alert-circle",
          statusIconColor: "error",
        },
        sending: {
          text: "Enviando Notificação",
          statusIcon: "mdi-clock-outline",
          statusIconColor: "gray",
        },
        pending: {
          text: "Enviando Notificação",
          statusIcon: "mdi-clock-outline",
          statusIconColor: "gray",
        },
        success: {
          text: "Notificação enviada",
          statusIcon: "mdi-check",
          statusIconColor: "gray",
        },
        RECEIVED: {
          text: "Notificação recebida",
          statusIcon: "mdi-check-all",
          statusIconColor: "gray",
        },
        READ: {
          text: "Notificação lida",
          statusIcon: "mdi-check-all",
          statusIconColor: "info",
        },
        SENT: {
          text: "Notificação enviada",
          statusIcon: "mdi-check-all",
          statusIconColor: "info",
        },
      },
    };
  },

  methods: {
    open(id) {
      this.$loading.start();
      this.$http
        .show("customer/customer-billing-notification", id)
        .then((response) => {
          // this.notification = response.notification;
          this.notification = JSON.parse(JSON.stringify(response.notification));
          this.dialog = true;
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },
    notificationStatus(notification) {
      if (notification.whatsapp_status) {
        return notification.whatsapp_status;
      }

      return notification.status;
    },
  },
};
</script>

<style>
</style>